import { saleContractNonUniqueWatchPathsFlat } from './sales-contract';

export const templateContractNonUniqueWatchPaths = {
  property: [
    ['saleAddrs'],
    ['saleTitles'],
    ['titleDivision']
  ],
  vendor: [['vendors'],['primaryVendor']],
  saleMethod: [
    ['sale', 'saleMethod'],
    ['sale', 'saleMethodOther'],
    ['auction']
  ],
  particulars: [
    ['chattels'],
    ['exclusions'],
    ['saaTenant', 'tenantEnable'],
    ['tenantsCollect'],
    ['encroach'],
    ['encroachEnable'],
    ['notiWorks'],
    ['notiWorksEnable'],
    ['vendorWorks'],
    ['specialTerms'],
    ['gstWithholdEnable'],
    ['pool']
  ]
};

export const templateContractNonUniqueWatchPathsFlat = Object.values(templateContractNonUniqueWatchPaths).flat();

export const templateContractRenderTriggerPathsFlat = [
  ...saleContractNonUniqueWatchPathsFlat,
  ['sale', 'saleMethod'],
  ['contractSpecial', 'financePermitted'],
  ['contractSpecial', 'purchaserSalePermitted']
];
